import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from '@remix-run/react';

export type SupportedLanguage = 'en' | 'fr';
export const ACTIVE_LANGUAGES: SupportedLanguage[] = ['fr', 'en'];
export const DEFAULT_LANGUAGE: SupportedLanguage = 'fr';

export default {
  // This is the list of languages your application supports
  supportedLngs: ACTIVE_LANGUAGES,
  // This is the language you want to use in case
  // if the user language is not in the supportedLngs
  fallbackLng: DEFAULT_LANGUAGE,
  // The default namespace of i18next is "translation", but you can customize it here
  // defaultNS: 'common',
  // Disabling suspense is recommended
  react: { useSuspense: false },
};

export function useChangeLang(locale: string | undefined) {
  const { i18n } = useTranslation();
  useEffect(() => {
    if (!locale) return;
    if (i18n.language !== locale) i18n.changeLanguage(locale);
  }, [locale, i18n]);
}

export const useLanguageParam = (): SupportedLanguage => {
  const { lang } = useParams();
  return (lang as SupportedLanguage) || DEFAULT_LANGUAGE;
};
